@tailwind components;
@tailwind utilities;
@import "common.scss";

// @font-face {
//   font-family: 'Gen Shin Gothic P';
//   src: url('../../public/static/fonts/GenShinGothic-P-Normal.ttf');
//   font-weight: 400;
// }

@font-face {
  font-family: 'shaolinsimp';
  src: url('../../public/static/fonts/FZShaoLGFTJW-v2.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'YunFengFeiYunTi';
  src: url('../../public/static/fonts/YunFengFeiYunTi-2.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'KaushanScript';
  src: url('../../public/static/fonts/KaushanScript-Regular-1.otf');
  font-weight: 400;
}

@font-face {
  font-family: 'RousseauDeco';
  src: url('../../public/static/fonts/RousseauDeco.ttf');
  font-weight: 400;
}


* {
  margin: 0;
  padding: 0;
}
html {
  font-family: Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft Yahei', '微软雅黑', Arial, sans-serif;
  background-color: #FFFFFF !important;
  // @media (min-width: 640px) { font-size: 10px; }
  // @media (min-width: 768px) { font-size: 10px; }
  // @media (min-width: 1024px) { font-size: 10px; }
  // @media (min-width: 1280px) { font-size: 10px; }
  // @media (min-width: 1536px) { font-size: 10px; }
  font-size: 16px;
}

.layout .main{
  width: 100%;
  height: calc(100% - 72px);
  overflow: auto;
}

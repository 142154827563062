/** common style **/
.flex-row-center{
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-row-start{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flex-row-end{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.flex-row-between{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-row-around{
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.flex-row-top{
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.flex-row-bottom{
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.flex-column-center{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.flex-column-start{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.flex-column-end{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.flex-column-between{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.flex-column-around{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.flex-column-left{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.flex-column-right{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
.flex-wrap{
  flex-wrap: wrap;
}

.ellipsis{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ellipsis-2{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.clearfix{
  content: '';
  display: block;
  clear: both;
}

.w100{
  width: 100%;
}

.zIndex-0{
  z-index: 0;
}
.zIndex-2{
  z-index: 2;
}

.display-n{
  display: none;
}

.disabled{
  color: #00000040;
  cursor: not-allowed!important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

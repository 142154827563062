$bar-color: #9d9db0;
$hover-color: darken($bar-color, 20%);
/* 容器样式 */
.scrollbar-container {
  /* 设置容器的高度和宽度，以展示滚动条 */
  //overflow: auto;

  /* 滚动条样式 */
  &::-webkit-scrollbar {
    width: 4px; /* 滚动条宽度 */
    background-color: #fff; /* 滚动条背景色 */
    border-radius: 8px; /* 滚动条边框圆角 */
  }

  &::-webkit-scrollbar-thumb {
    background-color: $bar-color; /* 滚动条滑块颜色 */
    border-radius: 8px; /* 滚动条滑块边框圆角 */
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: $hover-color; /* 滚动条滑块悬停时颜色 */
  }

  &::-moz-scrollbar {
    width: 4px; /* 滚动条宽度 */
    background-color: $bar-color; /* 滚动条背景色 */
    border-radius: 8px; /* 滚动条边框圆角 */
  }

  &::-moz-scrollbar-thumb {
    background-color: $bar-color; /* 滚动条滑块颜色 */
    border-radius: 8px; /* 滚动条滑块边框圆角 */
  }

  &::-moz-scrollbar-thumb:hover {
    background-color: $hover-color; /* 滚动条滑块悬停时颜色 */
  }

  &::-ms-scrollbar {
    width: 4px; /* 滚动条宽度 */
    background-color: #fff; /* 滚动条背景色 */
    border-radius: 8px; /* 滚动条边框圆角 */
  }

  &::-ms-scrollbar-thumb {
    background-color: $bar-color; /* 滚动条滑块颜色 */
    border-radius: 8px; /* 滚动条滑块边框圆角 */
  }

  &::-ms-scrollbar-thumb:hover {
    background-color: $hover-color; /* 滚动条滑块悬停时颜色 */
  }

  scrollbar-color: #fff $bar-color; /* Firefox 64+ */
  scrollbar-width: thin; /* Firefox 64+ */
}
